@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .flex1-50 {
        flex: 1 1 50%;
    }
    @media (max-width: 760px) {
        .flex1-50 {
            flex: 1 1 100%;
        }
    }
}

.bg {
	background-image: linear-gradient(to right top, #000000, #0c0c0c, #141414, #1b1b1b, #212121);
	z-index: -1;
}

.App {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #fff;
}
/* .show-on-scroll{
	animation-duration: 1s;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	opacity: 0;
}
.show-on-scroll.is-visible{
	opacity: 0;
	animation-name: fadeInUp;
	-webkit-animation-name: fadeInUp;
}
@keyframes fadeInUp {
	from {
		transform: translate3d(0,40px,0)
	}
	
	to {
		transform: translate3d(0,0,0);
		opacity: 1
	}
} */

.header {
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
	clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
	background: rgb(11 11 11 / 90%);
	padding-bottom: 20px;
}

.footer{
	-webkit-clip-path: polygon(0 0, 100% 60%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 60%, 100% 100%, 0 100%);
	background: rgb(11 11 11 / 90%);
    padding: 20px;
    height: 150px;
	margin-top: 9rem;
}
.nav__link--active {
	--tw-text-opacity: 1;
	color: rgba(37, 99, 235, var(--tw-text-opacity));
	border-bottom: 1px solid rgba(37, 99, 235, var(--tw-text-opacity));
}